.wrapper {    
  width: 100%;
}

.container {
  max-width: 1010px;
  padding: 1.5rem;
  margin: auto;
}

