@import 'mediaqueries';
@import 'variables';
@import "~react-id-swiper/lib/styles/scss/swiper.scss";
@import '~react-id-swiper/lib/styles/scss/swiper';
@import '~swiper/src/swiper.scss';

.swiper-pagination-bullet-active {
  background-color: $black;
}

.swiper-button-prev, .swiper-button-next {
  background-size: auto;
  width: 20px;
  height: 20px;
  margin: -10px 20px 0 20px;
}

.swiper-button-next {
  background-image: url('/static/images/pictos/icon-arrow-right.svg');
}

.swiper-button-prev {
  background-image: url('/static/images/pictos/icon-arrow-left.svg');
}


@include media(m-device) {
    .swiper-container {
      height: 100%;
    }

    .swiper-pagination-bullet-active {
      background-color: $black;
    }
}

.swiper-pagination-bullet-active {
  background-color: $black;
}
