@import "./variables";

@import "~reset-css/sass/reset";
@import "~bootstrap-grid-only-css/scss/bootstrap-grid.scss";
@import "./variables";
@import "./mediaqueries";
@import "./buttons";
@import "./layout";
@import "./visibility";
@import "./datepicker";
@import "./dropdown";
@import "./draftjs";
@import "./toast";
@import "./swiper";
@import "./spacing";

// Fonts:

a {
  font-size: 1rem;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

input,
label {
  font-size: 1rem;
}

button {
  border: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.fadeout {
  opacity: 0;
  transition: opacity 0.6s;
}

.fadein {
  opacity: 1;
  transition: opacity 0.6s;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

input:focus:-moz-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

/* Firefox 18- */
input:focus::-moz-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

/* Firefox 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

/* oldIE ;) */

textarea:focus::-webkit-input-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

textarea:focus:-moz-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

textarea:focus::-moz-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
  transition: color ease-in-out 0.3s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nowrap {
  white-space: nowrap;
  display: inline;
}

body {
  font-size: 16px;
  font-family: $baderNarrow;
  color: #000000;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  &__content {
    position: relative;
    min-height: calc(100vh - 100px);
    background-color: #ffffff;
  }
}

.noScroll {
  overflow: hidden;
  height: 100vh;
}

// after css migration
.blue {
  color: #0d6aad;
  white-space: nowrap;
  text-decoration: underline;
  font-family: $baderNarrowBold;
}

body {
  margin: 0;
}

.cat-0 {
  background-image: url("/static/images/categories/default-img-0.jpg");
  background-color: #eac1c5;
}

.cat-1 {
  background-image: url("/static/images/categories/default-img-1.jpg");
  background-color: #78dbff;
}

.cat-2 {
  background-image: url("/static/images/categories/default-img-2.jpg");
  background-color: #f9b81e;
}

.cat-3 {
  background-image: url("/static/images/categories/default-img-3.jpg");
  background-color: #fabdc4;
  background-position: bottom 50% right 50% !important;
  @include media(m-device) {
    background-position: bottom 7% right 50% !important;
  }
}

.cat-4 {
  background-image: url("/static/images/categories/default-img-4.jpg");
  background-color: #d2e0e8;
  background-position: bottom 0% right 50% !important;
}

.cat-5 {
  background-image: url("/static/images/categories/default-img-5.jpg");
  background-color: #3ec0eb;
}

.cat-6 {
  background-image: url("/static/images/categories/default-img-6.jpg");
  background-color: #fbd58d;
}

.cat-7 {
  background-image: url("/static/images/categories/default-img-7.jpg");
  background-color: #daa3a6;
}

.cat-8 {
  background-image: url("/static/images/categories/default-img-8.jpg");
  background-color: #e9b31b;
}

// KycList.module.scss
.before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
}

.status_0::before {
  @extend .before;
  background-image: url("/static/images/pictos/kyc-needed.svg");
}

.status_1::before,
.status_2::before {
  @extend .before;
  background-size: 20px;
  background-image: url("/static/images/pictos/kyc-waiting.svg");
}

.status_3::before {
  @extend .before;
  background-image: url("/static/images/pictos/icon-check-success.svg");
}

.status_4::before {
  @extend .before;
  background-image: url("/static/images/pictos/kyc-refused.svg");
}

// ValidateTaxStrongAuthModal.module.scss
.before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
}

.Pending::before {
  @extend .before;
  background-size: 20px;
  background-image: url("/static/images/pictos/kyc-waiting.svg");
}

.Succeeded::before {
  @extend .before;
  background-image: url("/static/images/pictos/icon-check-success.svg");
}

.Failed::before {
  @extend .before;
  background-image: url("/static/images/pictos/kyc-refused.svg");
}

.is-closed {
  &.is-disabled {
    h2 {
      color: $very-light-pink;
    }
  }
}

.flex {
  display: flex;
}

.responsive-flex {
  display: flex;
  @include media(m-device) {
    flex-direction: column;
  }
}

.space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-right {
  align-items: right;
}

.t-center {
  text-align: center;
}

.f-width {
  width: 100% !important;
}

.f-height {
  height: 100% !important;
}

.mt-8 {
  margin-top: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mt-16 {
  margin-top: 16px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.t-primary {
  color: $primary;
}

.t-underline {
  text-decoration: underline;
}

.t-black {
  color: $black;
}

.t-bold {
  font-family: $baderNarrowBold;
}

.pointer {
  cursor: pointer;
}

.hide-mobile {
  @include media(m-device) {
    display: none !important;
  }
}

.show-mobile {
  display: none !important;
  @include media(m-device) {
    display: unset !important;
  }
}
