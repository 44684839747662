@import '~react-dates/lib/css/_datepicker.css';


.SingleDatePicker {
  width: 100%;

  .SingleDatePickerInput {
    width: 100%;
    border: none;

    .DateInput {
      width: 100%;
      border: none;

      .DateInput_input {
        width: 100%;
        height: 50px;
        padding: 1rem;
        color: $black;
        font-size: 1rem;

        border: none;
        border-radius: 4px;
        outline: none;
        background-color: #ffffff;

        font-family: $baderNarrow;


        &::placeholder {
          color: $placeholder-color;
          font-style: italic;
          font-size: 16px;
          font-family: $baderNarrow;
        }

        &__disabled {
          background-color: $very-light-grey !important;
          font-style: italic !important;
        }
      }
    }

    .SingleDatePicker_picker {
      .DayPicker {
        .DayPicker_weekHeaders {
          .DayPicker_weekHeader {
            top: 57px;
          }
        }

        .DayPicker_focusRegion {

          .DayPickerNavigation {
            z-index: 4;
          }

          .DayPickerKeyboardShortcuts_buttonReset {
            display: none;
          }

          .CalendarMonth_caption {
            padding-bottom: 40px;
          }

          .CalendarMonthGrid {
            background: transparent;
            z-index: 3;

            .CalendarMonth {
              background: transparent;
            }
          }

          .CalendarMonth_table {
            tr {
              td {
                vertical-align: middle;

                &.CalendarDay__selected, &.CalendarDay__selected:active, &.CalendarDay__selected:active {
                  background: $primary;
                  border: 1px solid $primary;
                }

                &.CalendarDay__blocked_calendar, &.CalendarDay__blocked_calendar:active, &.CalendarDay__blocked_calendar:hover {
                  background: #fff;
                  border: 1px solid #e4e7e7;
                  color: #cacccd;
                }
              }
            }
          }
        }
      }
    }
  }
}
