@import "variables";
@import "mediaqueries";

.Dropdown-placeholder {
  &.is-selected {
    color: $black;
  }
}

.Dropdown-option {
  padding: 1rem;
  border-top: 1px solid #dcdcdc;
  font-family: $baderCrompress;

  &:hover {
    background-color: $very-light-grey;
  }

  &:first-of-type {
    border: none;
  }

  &.is-selected {
    background-image: url("/static/images/pictos/icon-check-primary.svg");
    background-repeat: no-repeat;
    background-position: right 10px top 17px;
  }
}



.Dropdown-root {
  margin-bottom: 0px;
  border: none;

  .Dropdown-control {
    padding: 15px;

    .Dropdown-arrow-wrapper {
      .Dropdown-arrow {
        @include media(m-device) {
          z-index: -1;
        }
        width: 16px !important;
        height: 16px !important;
        right: 16px !important;
        top: 16px !important;
        background-size: contain !important;
      }
    }
  }

  .Dropdown-menu {
    width: auto;
    z-index: 15;
    background-color: $white;
    padding: 0px;

    .Dropdown-option {
      padding: 10px 28px 10px 10px;

      &.is-selected {
        background-position: right 5px top 17px;
        background-size: 15px;
      }
    }
  }
}
