@import "mediaqueries";

.react-toast-notifications__toast__icon-wrapper,
.react-toast-notifications__toast__dismiss-button {
  display: none;
}

.react-toast-notifications__toast__content {
  font-size: 16px !important;
  padding: 0px;
  min-height: auto;

  @include media(m-device) {
    max-width: 95%;
  }
}
