@import "../../static/styles/variables";
@import "../../static/styles/mediaqueries";

.toast {
    background-color: $white !important;
    border-radius: 5px;
    position: relative;
    z-index: 200;
    padding: 20px 50px 20px 20px;
    color: $black !important;

    @include media(m-device) {
        max-width: 90vw;
    }

    /*
    :global(.react-toast-notifications__toast__icon-wrapper),
    :global(.react-toast-notifications__toast__dismiss-button) {
        display: none;
    }

    :global(.react-toast-notifications__toast__content) {
        font-size: 16px !important;
        padding: 0px;
        min-height: auto;

        @include media(m-device) {
            max-width: 95%;
        }
    }

     */

    &__success {
        border-left: 4px solid $secondary;
    }

    &__error {
        border-left: 4px solid $warning;
    }

    &__warning {
        border-left: 4px solid $primary;
    }

    &__info {
        border-left: 4px solid $black;
    }

    &__close_button {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border: solid 1px $very-light-pink;
        cursor: pointer;
        outline: none;
        background: $white;
        right: 10px;
        top: 10px;
        line-height: 13px;

        img {
            width: 15px;
            height: 15px;
        }

        &:hover,
        &:focus {
            outline: none;
        }
    }
}
