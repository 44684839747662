@import './variables';
@import './mediaqueries';

.hidden {
    display: none !important;
}

.hidden__xs {
    @include media(xs-device) {
        display: none !important;
    }
}

.hidden__s {
    @include media(s-device) {
        display: none !important;
    }
}

/**
 * Use this if you want to hide from Mobile
 **/
.hidden__m {
    @include media(m-device) {
        display: none !important;
    }
}

/**
 * Use this if you want to hide from Desktop
 **/
.hidden__l {
    @include media(l-device) {
        display: none !important;
    }
}

.hidden__xl {
    @include media(xl-device) {
        display: none !important;
    }
}
